import React, { useState, useContext, useEffect, PropsWithChildren, useRef, useMemo, useCallback, memo, Suspense } from 'react';
import {
  Taker,
  TakerDocument,
  TakerDocumentUpload,
  TakerDocumentUploadAnalysis,
  TakerDocumentAnalysis,
  TakerAccessType,
  TakerDocumentSettingsPropertyType,
  TakerDocumentData,
  TakerDocumentReviewComment,
  CommentType
} from "../../redux/models/dataModelTypes";
import { FulfillmentStateHolder, TakerStateHolder } from "../../types/taker";
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import { dummyTakerStateData } from './dummyTakerStateData';
import {
  useUpdateTakerMutation,
  useAddContentFilteringAnalysisMutation,
  useGetTakerDocumentQuery,
  useAddTakerDocumentReviewCommentMutation,
  useUpdateTakerDocumentReviewCommentMutation,
} from '../../redux/services/taker';
import {
  useGetTakerDocumentStateV2Query,
  useGetLatestTakerDocumentDataQuery,
  useUpdateTakerDocumentQuestionnaireMutation
} from '../../redux/services/takerData';
import { VariableReference } from '../../types/builderv2.generated';
import { ApiError } from '../../redux/reduxUtils/baseQuery';
import { useReadOnlyBuilderData } from '../ReadOnlyBuilderData/ReadOnlyBuilderData';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../types/taker/uidatastate.generated';
import { useUserScopedAppData } from '../UserScopedAppData/UserScopedAppData';
import { useLocalStorage } from '@uidotdev/usehooks';

const SAVE_DELAY = 2000;
const METADATA_SAVE_DELAY = 1000;
const DURATION_MINUTES_MS = 15 * 60 * 1000;

const TOP_CENTER_ERROR_OPTION = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
} as OptionsWithExtraProps<'error'>;

const dummyTakerState = new TakerStateHolder(dummyTakerStateData);

type TakerNameGetter = () => string | null;

interface TakerPermissionState {
  allGrants: TakerAccessType[];
  isUnknown: boolean;
  isForbidden: boolean;
  isRead: boolean;
  isReadWrite: boolean;
  isAdmin: boolean;
};

export interface QuestionnaireDataService {
  updateAnswer: (moduleIds: string[], q: string, value: any, iteration: number | null) => void;
  updateAnswers: (moduleIds: string[], nameValuePairs: { name: string; value: any; }[], iteration: number | null) => void;
  updateAnalysis: (moduleIds: string[], q: string, value: string, iteration: number | null) => void;
  updateReference: (moduleIds: string[], q: string, value: CommentaryOrGuidanceReference[], iteration: number | null) => void;
  updateSource: (moduleIds: string[], q: string, value: KeyTermSource[], iteration: number | null) => void;
  getAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getReference: (moduleIds: string[], q: string, iteration: number | null) => CommentaryOrGuidanceReference[];
  getSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  getMockAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getMockAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getMockReference: (moduleIds: string[], q: string, iteration: number | null) => CommentaryOrGuidanceReference[];
  getMockSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  addIterations: (moduleIds: string[], n: number) => void;
  removeIteration: (moduleIds: string[], iteration: number) => void;
  count: (moduleIds: string[]) => number;
};

interface TakerStateHookData {
  isSaving: boolean;
  initTakerStateCount: number;
  isTakerDocumentUninitialized: boolean;
  isQuestionnaireUninitialized: boolean;
  isTakerDocumentLoading: boolean;

  taker: Taker | undefined;
  lastSavedTimestamp: number | undefined;
  takerDocumentId: string;
  latestQuestionnaireDataId?: string;
  latestQuestionnaireData?: TakerDocumentData | undefined;
  takerDocumentUploads: Array<TakerDocumentUpload> | undefined;
  activeTakerDocument: TakerDocument | null;
  refetchTakerDocument: () => void;

  takerDocumentAnalysesInProgress: string[];
  takerDocumentUploadAnalysesInProgress: string[];

  takerPermissionState: TakerPermissionState;

  latestContentFilterAnalyses: Record<string, TakerDocumentUploadAnalysis>;
  latestExecSummaryAnalyses: Array<TakerDocumentAnalysis>;
  latestDocQAAnalyses: Array<TakerDocumentAnalysis>;

  createContentFilteringAnalyses: (tdu: TakerDocumentUpload, fiid: string) => void;

  takerName: string;
  updateTakerName: (n: string) => void;
  activeTargetTakerState: TakerStateHolder;
  isTakerStateDirty: boolean;

  questionnareDataService: QuestionnaireDataService;

  takerOutput: any;
  fulfillmentStateHolder: FulfillmentStateHolder | undefined,
  loadingTakerOutput: boolean;
  getVariableValue: (mids: string[], varRef: VariableReference) => any;
  getActiveSetting: (type: TakerDocumentSettingsPropertyType) => any;

  // comments
  commentsOpen: boolean;
  setCommentsOpen: (o: boolean) => void;
  reviewComments: Array<TakerDocumentReviewComment>;
  addReviewComment: (
    lexical: any,
    text: string,
    commentType: CommentType,
    commentMetadata: Record<string, any>
  ) => void;
  updateReviewComment: (
    commentId: string,
    lexical: any,
    text: string
  ) => void;
}

type TakerStateHook = () => TakerStateHookData;

const Context = React.createContext({});

export const useTakerState: TakerStateHook = () => {
  return useContext(Context) as TakerStateHookData;
}


interface TakerDocumentContainerProps {
  originalTaker: Taker;
  takerDocumentId: string;
  userId: string;
}

const TakerDocumentContainer: React.FC<PropsWithChildren<TakerDocumentContainerProps>> = ({
  originalTaker,
  takerDocumentId,
  userId,
  children
}) => {
  const [updatingJob, setUpdatingJob] = useState<any>(null);
  const [updatingMetadataJob, setUpdatingMetadataJob] = useState<any>(null);
  const [initTakerStateCount, setInitTakerStateCount] = useState<number>(0);
  const [takerName, setTakerName] = useState<string | undefined>(undefined);
  const [isStateDirty, setIsStateDirty] = useState<boolean>(false);
  const [lastMarkedClean, setLastMarkedClean] = useState<number>();
  const [commentsOpen, setCommentsOpen] = useLocalStorage<boolean>(`${takerDocumentId}-commentsOpen`, false);

  const preventUpdateOutput = useRef(false);

  const {
    enqueueSnackbar,
    closeSnackbar
  } = useSnackbar();

  const [updateTaker, updateTakerResult] = useUpdateTakerMutation();
  const [addContentFilteringAnalysis, addContentFilteringAnalysisResult] = useAddContentFilteringAnalysisMutation();
  const { defaultTakerDocumentSettings } = useReadOnlyBuilderData();
  const [
    addTakerDocumentReviewComment,
    addTakerDocumentReviewCommentRes
  ] = useAddTakerDocumentReviewCommentMutation();
  const [
    updateTakerDocumentReviewComment,
    updateTakerDocumentReviewCommentRes
  ] = useUpdateTakerDocumentReviewCommentMutation();

  const [
    updateTakerDocumentQuestionnaire,
    updateTakerDocumentQuestionnaireRes
  ] = useUpdateTakerDocumentQuestionnaireMutation()

  const {
    data: latestQuestionnaireData,
    isUninitialized: isQuestionnaireDataUninitialized,
    isError: isQuestionnaireDataError
  } = useGetLatestTakerDocumentDataQuery({ takerDocumentId, contentType: "QUESIONNAIRE" });

  const { flagProvider } = useUserScopedAppData();

  const enableQuestionnaireCaching = useMemo(() => {
    let flags = flagProvider.populateFlagValues([
      "FEATURE_QUESTIONNAIRE__enable_fulfillment_caching"
    ])
    if (flags.FEATURE_QUESTIONNAIRE__enable_fulfillment_caching === "TRUE") {
      return true;
    } else {
      return false
    }
  }, [flagProvider])

  const {
    data: activeTakerDocument,
    isFetching: isTakerDocumentLoading,
    isUninitialized: isTakerDocumentUninitialized,
    refetch: refetchTakerDocument
  } = useGetTakerDocumentQuery(takerDocumentId, {
    refetchOnReconnect: true,
    pollingInterval: 10000
  });

  const {
    data: takerDocumentStateV2,
    isSuccess: isTakerDocumentStateV2Success,
    isFetching: isTakerDocumentStateV2IsFetching
  } = useGetTakerDocumentStateV2Query({ id: takerDocumentId, enableCaching: enableQuestionnaireCaching ? "TRUE" : "FALSE" })

  useEffect(() => {
    if (isStateDirty && takerDocumentStateV2) {
      setIsStateDirty(false);
      setLastMarkedClean(new Date().getTime());
    }
  }, [takerDocumentStateV2]);

  const [takerDocumentUploadsState, setTakerDocumentUploads] = useState<Array<TakerDocumentUpload> | undefined>(activeTakerDocument?.takerDocumentUploads);

  const takerDocumentAnalysesInProgress = useMemo(() => {
    if (!activeTakerDocument) {
      return [];
    }

    const now = new Date().getTime();
    const inProgressList: string[] = [];

    const checkForPendingJobs = (analyses?: TakerDocumentAnalysis[]) => {
      if (!analyses) {
        return;
      }

      for (const a of analyses) {
        // don't consider old jobs
        if ((now - a.createdAt) > DURATION_MINUTES_MS) {
          continue;
        }
        
        if (a.state === "PENDING_GENERATION" || a.state === "CREATED") {
          inProgressList.push(a.id);
        }
      }
    }

    checkForPendingJobs(activeTakerDocument?.executiveSummaryTakerDocumentAnalyses)
    checkForPendingJobs(activeTakerDocument?.contractQATakerDocumentAnalyses)
    checkForPendingJobs(activeTakerDocument?.memoGenerationTakerDocumentAnalyses);

    return inProgressList;
  }, [
    activeTakerDocument?.executiveSummaryTakerDocumentAnalyses,
    activeTakerDocument?.contractQATakerDocumentAnalyses,
    activeTakerDocument?.memoGenerationTakerDocumentAnalyses,
  ]);

  const takerDocumentUploadAnalysesInProgress = useMemo(() => {
    if (!activeTakerDocument) {
      return [];
    }

    const now = new Date().getTime();
    const inProgressList: string[] = [];

    const checkForPendingGroupJobs = (uploadAnalyses?: TakerDocumentUploadAnalysis[]) => {
      if (!uploadAnalyses) {
        return;
      }

      for (const a of uploadAnalyses) {
        // don't consider old jobs
        if ((now - a.createdAt) > DURATION_MINUTES_MS) {
          continue;
        }

        if (a.state === "PENDING_GENERATION") {
          inProgressList.push(a.id);
        }
      }
    }

    for (const upload of activeTakerDocument?.takerDocumentUploads || []) {
      checkForPendingGroupJobs(upload.taggingAndSummarizationTakerDocumentUploadAnalyses);
      checkForPendingGroupJobs(upload.contentFilteringTakerDocumentUploadAnalyses);
      checkForPendingGroupJobs(upload.singleKeyTermsTakerDocumentUploadAnalyses);
    }

    return inProgressList;
  }, [activeTakerDocument?.takerDocumentUploads]);

  useEffect(() => {
    setTakerDocumentUploads(activeTakerDocument?.takerDocumentUploads);
  }, [activeTakerDocument]);

  useEffect(() => {
    if (addContentFilteringAnalysisResult.isError && addContentFilteringAnalysisResult.error) {
      enqueueSnackbar(
        (addContentFilteringAnalysisResult.error as ApiError).data,
        TOP_CENTER_ERROR_OPTION
      );
    }
  }, [addContentFilteringAnalysisResult]);

  const takerPermissionState: TakerPermissionState = useMemo(() => {
    if (!activeTakerDocument) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    if (!activeTakerDocument.state) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    const allGrants: TakerAccessType[] = [];
    if (originalTaker.ownerUserId === userId) {
      allGrants.push("OWNER");
    }

    for (const takerDocumentAccessGrant of activeTakerDocument.takerDocumentAccessGrants) {
      if (takerDocumentAccessGrant.userId === userId) {
        allGrants.push(takerDocumentAccessGrant.type);
      }
    }

    if (allGrants.length === 0) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: true,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    let hasRead = allGrants.includes("READ");
    let hasOwner = allGrants.includes("OWNER");
    let hasReview = allGrants.includes("REVIEW");

    let inCompleted = activeTakerDocument.state.reviewState === "COMPLETED";
    if (inCompleted && (allGrants.length > 0)) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: false,
        isRead: true,
        isReadWrite: false,
        isAdmin: hasOwner
      };
    }

    let inReview = activeTakerDocument.state.reviewState === "PENDING";
    return {
      allGrants,
      isUnknown: false,
      isForbidden: false,
      isRead: (hasRead || hasOwner || hasReview),
      isReadWrite: (!inReview && hasOwner) || (inReview && hasReview),
      isAdmin: hasOwner
    };
  }, [activeTakerDocument, originalTaker, userId]);

  // Decides between the mutated state and the original state.
  const activeTargetTakerState = useRef<TakerStateHolder | undefined>(
    latestQuestionnaireData && new TakerStateHolder(latestQuestionnaireData.content)
  );

  useEffect(() => {
    if (!latestQuestionnaireData) {
      return;
    }

    activeTargetTakerState.current = new TakerStateHolder(latestQuestionnaireData.content);
    setInitTakerStateCount(initTakerStateCount + 1);
  }, [latestQuestionnaireData?.id]);

  const reviewComments = useMemo(() => {
    if (!activeTakerDocument || !activeTakerDocument.state) {
      return [];
    }

    let sortedReviewComments = [...activeTakerDocument.state.reviewComments];
    sortedReviewComments.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1);
    return sortedReviewComments;
  }, [
    activeTakerDocument,
    addTakerDocumentReviewCommentRes,
    updateTakerDocumentReviewCommentRes
  ]);

  const addReviewComment = useCallback(
    (
      lexical: any,
      text: string,
      commentType: "TOP_LEVEL" | "DOCUMENT_HIGHLIGHT",
      commentMetadata: Record<string, any>
    ) => {
      addTakerDocumentReviewComment({
        takerDocumentId,
        commentType,
        commentLexical: lexical,
        commentText: text,
        commentMetadata
      });
    },
    [
      takerDocumentId
    ]
  );

  const updateReviewComment = useCallback(
    (
      commentId: string,
      lexical: any,
      text: string
    ) => {
      updateTakerDocumentReviewComment({
        id: commentId,
        takerDocumentId,
        commentLexical: lexical,
        commentText: text,
      });
    },
    [
      takerDocumentId
    ]
  );

  // Decides between the mutated state and the original state.
  const getTakerName: TakerNameGetter = () => {
    if (!originalTaker) {
      return null;
    }
    return takerName ? takerName : originalTaker.name;
  };

  let latestContentFilterAnalyses: Record<string, TakerDocumentUploadAnalysis> = {};
  let latestDocQAAnalyses: Array<TakerDocumentAnalysis> = [];
  let latestExecSummaryAnalyses: Array<TakerDocumentAnalysis> = [];

  if (activeTakerDocument) {
    for (const tdu of activeTakerDocument.takerDocumentUploads) {
      const sortedContentFilterAnalysis = tdu.contentFilteringTakerDocumentUploadAnalyses.slice().sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
      if (sortedContentFilterAnalysis.length > 0) {
        latestContentFilterAnalyses[tdu.id] = sortedContentFilterAnalysis[0];
      }
    }

    const sortedDocQAAnalysis = activeTakerDocument.documentQATakerDocumentAnalyses.slice().sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
    latestDocQAAnalyses = sortedDocQAAnalysis;

    const sortedExecSummaryAnalyses = activeTakerDocument.executiveSummaryTakerDocumentAnalyses.slice().sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
    latestExecSummaryAnalyses = sortedExecSummaryAnalyses;
  }

  const getActiveSetting = (type: TakerDocumentSettingsPropertyType) => {
    if (activeTakerDocument?.settings) {
      for (const property of activeTakerDocument?.settings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }

    if (defaultTakerDocumentSettings) {
      for (const property of defaultTakerDocumentSettings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }
  }

  const createContentFilteringAnalyses = (takerDocumentUpload: TakerDocumentUpload, fileItemId: string) => {
    const enableKeyTermTagging = getActiveSetting("KEY_TERM_TAGGING_ENABLED") === "true";
    addContentFilteringAnalysis({
      takerId: originalTaker.id,
      takerDocumentId,
      takerDocumentUpload: takerDocumentUpload,
      enableKeyTermTagging,
      fileItemId
    });
  };

  // Do the taker output computation when the state changes. Save with a delay as to not overwhelm the server.
  const doTakerStateUpdateFunc = (newTakerState: TakerStateHolder, doImmediateUpdate: boolean) => {
    // no updates for non write roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    activeTargetTakerState.current = newTakerState;

    if (updatingJob) {
      clearTimeout(updatingJob)
    }

    function doUpdate() {
      if (latestQuestionnaireData && activeTargetTakerState.current) {
        updateTakerDocumentQuestionnaire({
          takerId: originalTaker.id,
          takerDocumentId,
          id: latestQuestionnaireData.id,
          content: activeTargetTakerState.current.uiDataSchema
        });
        preventUpdateOutput.current = false;
      }
    }

    setIsStateDirty(true);
    if (doImmediateUpdate) {
      doUpdate();
    } else {
      let newUpdatingJob = setTimeout(doUpdate, SAVE_DELAY);
      setUpdatingJob(newUpdatingJob);
    }
  };

  const doTakerStateUpdate = useRef(doTakerStateUpdateFunc);
  useEffect(() => {
    doTakerStateUpdate.current = doTakerStateUpdateFunc;
  });

  const getVariableValue = (
    currentModuleIds: string[],
    varRef: VariableReference,
    currentTakerDocumentStateV2: any
  ) => {
    if (!currentTakerDocumentStateV2) {
      return;
    }

    let scopedVarsPtr = currentTakerDocumentStateV2["scoped_variables"];

    // This refers to a local variable, return from currentModuleIds scope.
    if (!varRef.modulePath) {
      const midsCopy = [...currentModuleIds];
      let firstMid = midsCopy.splice(0, 1)[0];
      if (firstMid !== scopedVarsPtr["module_id"]) {
        throw Error(`${firstMid} was not the first module ID in the path`)
      }

      while (midsCopy.length > 0) {
        let firstMid = midsCopy.splice(0, 1)[0];
        let found = false;
        for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
          if (sv["module_id"] === firstMid) {
            scopedVarsPtr = sv;
            found = true;
            break;
          }
        }
        if (!found) {
          throw Error(`${firstMid} was not found in the available path`);
        }
      }
      return scopedVarsPtr["internal_variables"][varRef.variableName];
    }

    const midsCopy = [...varRef.modulePath];
    let firstMid = midsCopy.splice(0, 1)[0];
    if (firstMid !== scopedVarsPtr["module_id"]) {
      throw Error(`${firstMid} was not the first module ID in the path`)
    }

    while (midsCopy.length > 0) {
      let firstMid = midsCopy.splice(0, 1)[0];
      let found = false;
      for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
        if (sv["module_id"] === firstMid) {
          scopedVarsPtr = sv;
          found = true;
          break;
        }
      }
      if (!found) {
        throw Error(`${firstMid} was not found in the available path`);
      }
    }
    return scopedVarsPtr["exported_variables"][varRef.variableName];
  };

  // Save our metadata with some delay to not overwhelm the server with updates
  useEffect(() => {
    // no updates for non read/roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    if (updatingMetadataJob) {
      clearTimeout(updatingMetadataJob)
    }
    let updateJob = setTimeout(() => {
      if (takerName) {
        updateTaker({
          id: originalTaker.id,
          name: takerName,
          description: originalTaker.description
        });
      }
    }, METADATA_SAVE_DELAY);
    setUpdatingMetadataJob(updateJob);
    return () => clearTimeout(updateJob);
  }, [takerName]);

  const lastSavedTimestamp = useMemo(
    () => !isQuestionnaireDataError && (lastMarkedClean || latestQuestionnaireData?.updatedAt),
    [
      isQuestionnaireDataError,
      lastMarkedClean,
      latestQuestionnaireData
    ]
  );

  return (
    <Context.Provider
      value={{
        // raw data for consumer
        taker: originalTaker,
        activeTargetTakerState: activeTargetTakerState.current,
        takerDocumentUploads: takerDocumentUploadsState,
        refetchTakerDocument,

        takerDocumentAnalysesInProgress,
        takerDocumentUploadAnalysesInProgress,

        // Data state, for loading and initializing page
        isSaving: updateTakerResult.isLoading,
        initTakerStateCount: initTakerStateCount,
        isTakerDocumentUninitialized: isTakerDocumentUninitialized,
        isQuestionnaireUninitialized: isQuestionnaireDataUninitialized || isTakerDocumentUninitialized,
        isTakerDocumentLoading,

        lastSavedTimestamp,
        takerDocumentId,
        latestQuestionnaireDataId: latestQuestionnaireData?.id,
        latestQuestionnaireData,

        // TODO: this may not always update

        activeTakerDocument,

        latestContentFilterAnalyses,
        latestExecSummaryAnalyses,
        latestDocQAAnalyses,

        takerPermissionState,

        takerName: getTakerName(),

        updateTakerName: (n: string) => setTakerName(n),

        questionnareDataService: {
          updateAnswer(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnswer(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnswers(moduleIds, nameValuePairs, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < nameValuePairs.length; i++) {
                newTargetState.updateAnswer(moduleIds, nameValuePairs[i].name, nameValuePairs[i].value, iteration);
              }
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnalysis(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnalysis(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateReference(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateReference(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateSource(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateSource(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          getAnswer: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnswer(moduleIds, q, iteration),
          getAnalysis: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnalysis(moduleIds, q, iteration),
          getReference: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getReference(moduleIds, q, iteration),
          getSource: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getSource(moduleIds, q, iteration),
          getMockAnswer: (moduleIds, q, iteration) =>
            dummyTakerState.getAnswer(moduleIds, q, iteration),
          getMockAnalysis: (moduleIds, q, iteration) =>
            dummyTakerState.getAnalysis(moduleIds, q, iteration),
          getMockReference: (moduleIds, q, iteration) =>
            dummyTakerState.getReference(moduleIds, q, iteration),
          getMockSource: (moduleIds, q, iteration) =>
            dummyTakerState.getSource(moduleIds, q, iteration),
          addIterations(moduleIds, n) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < n; i++) {
                newTargetState.addIteration(moduleIds);
              }
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          removeIteration(moduleIds, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.removeIteration(moduleIds, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          count: (moduleIds) =>
            activeTargetTakerState.current && activeTargetTakerState.current.count(moduleIds)
        } as QuestionnaireDataService,

        takerOutput: takerDocumentStateV2,

        // fulfillment state should be undefined until the API returns a response
        fulfillmentStateHolder: isTakerDocumentStateV2Success &&
          takerDocumentStateV2['fulfillment_state'] &&
          new FulfillmentStateHolder(takerDocumentStateV2['fulfillment_state']),

        isTakerStateDirty: isStateDirty,

        createContentFilteringAnalyses,

        getVariableValue: (mids: string[], varRef: VariableReference) =>
          getVariableValue(mids, varRef, takerDocumentStateV2),

        getActiveSetting,

        commentsOpen,
        setCommentsOpen,
        reviewComments,
        addReviewComment,
        updateReviewComment
      }}>
      {children}
    </Context.Provider>
  );
};

export default memo(TakerDocumentContainer);