import {
    Alert,
    AlertTitle,
    Box,
    Tab,
    Tabs,
} from "@mui/material/";
import { RJSFSchema } from '@rjsf/utils';
import { useEffect, useState } from "react";
import axios from 'axios';
import { BuilderDocument } from "../../redux/models/dataModelTypes";
import { Root } from "../../types/builderv2.generated";
import { useUpdateBuilderDocumentMutation } from "../../redux/services/builder";
import KeyTermsEditor from "./SpecBuilder/keyTerms";
import TabPanel, { a11yProps } from "../../components/navigation/TabPanel";
import ModuleEditor from "./SpecBuilder/module";
import { useSnackbar } from "notistack";
import VariableTree from "./SpecBuilder/variableTree";
import Reports from "./SpecBuilder/reports";
import { BuilderDocumentProvider } from "./SpecBuilder/CustomCommentaryWidget/context";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

interface Props {
    initialBuilderDocument: BuilderDocument;
}

const BuilderEditorV2 = ({ initialBuilderDocument }: Props) => {
    const [fullConfig, setFullConfig] = useState<Root>(initialBuilderDocument.configuration as Root);
    const [tabValue, setTabValue] = useState(0);
    const [schema, setSchema] = useState<RJSFSchema | null>(null);
    const navigate = useNavigate();

    const [guidanceErrors, setGuidanceErrors] = useState<string[]>([]);
    const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);

    const { enqueueSnackbar } = useSnackbar();
    const [updateBuilderDocument, updateBuilderDocumentResult] = useUpdateBuilderDocumentMutation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    useEffect(() => {
        if (updateBuilderDocumentResult.isSuccess) {
            enqueueSnackbar("Automation Saved", {
                key: "automation-saved",
                preventDuplicate: true,
                variant: "info",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
            setFullConfig(updateBuilderDocumentResult.data.configuration);
        } else if (updateBuilderDocumentResult.isError) {
            let errorMessages = (updateBuilderDocumentResult.error as any)['data'];
            let fErrors = errorMessages['fulfillment_errors'];
            let gErrors = errorMessages['guidance_errors'];
            setFulfillmentErrors(fErrors.map((e: any) => JSON.stringify(e)));
            setGuidanceErrors(gErrors);
            enqueueSnackbar("There were errors in the automation", {
                key: "automation-saved",
                preventDuplicate: true,
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    }, [updateBuilderDocumentResult])

    useEffect(() => {
        setFullConfig(initialBuilderDocument.configuration);
    }, [initialBuilderDocument]);

    useEffect(() => {
        axios.get(
            `${API_ENDPOINT}/schemas/builderv2.schema.json`,
            { withCredentials: true }
        ).then((res) => {
            const resData = res.data;
            setSchema(resData as RJSFSchema);
        }).catch(() => {

        });
    }, []);

    return (
        <BuilderDocumentProvider builderDocument={initialBuilderDocument}>
            <Box
                sx={{
                    padding: 1,
                    height: "100%",
                }}
            >
                {guidanceErrors.map((e) => (
                    <Alert color="warning">
                        {e}
                    </Alert>
                ))}
                {fulfillmentErrors.length > 0 && (
                    <Alert color="warning">
                        <AlertTitle>
                            There were errors with fulfillment
                        </AlertTitle>
                        <Box>
                            <ul>
                                {fulfillmentErrors.map(fe => (
                                    <li>{fe}</li>
                                ))}
                            </ul>
                        </Box>
                    </Alert>
                )}
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                >
                    <Tab label="Modules" {...a11yProps(0)} />
                    <Tab label="Variables" {...a11yProps(1)} />
                    <Tab label="Reports" {...a11yProps(2)} />
                    <Tab label="Key Terms" {...a11yProps(3)} />
                    <Tab label="Full GPS" 
                        {...a11yProps(4)} 
                        onClick={() => {
                            navigate(`/mainBuilder/${initialBuilderDocument.builderId}/graphEditor`);
                        }}
                    />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <Box
                        height="100%"
                        sx={{
                            overflowY: "scroll"
                        }}
                    >
                        {schema && fullConfig.topModule && (
                            <ModuleEditor
                                initialTopModule={fullConfig.topModule}
                                fullSchema={schema}
                                onUpdate={(tm) => {
                                    const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
                                    newConfig.topModule = tm;
                                    updateBuilderDocument({
                                        id: initialBuilderDocument.id,
                                        configuration: newConfig
                                    });
                                }}
                            />
                        )}
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Box
                        height="100%"
                        sx={{
                            overflowY: "scroll"
                        }}
                    >
                        {schema && fullConfig.topModule && (
                            <VariableTree
                                initialTopModule={fullConfig.topModule}
                                fullSchema={schema}
                                onUpdate={(tm) => {
                                    const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
                                    newConfig.topModule = tm;
                                    updateBuilderDocument({
                                        id: initialBuilderDocument.id,
                                        configuration: newConfig
                                    });
                                }}
                            />
                        )}
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Box
                        height="100%"
                        sx={{
                            overflowY: "scroll"
                        }}
                    >
                        {schema && (
                            <Reports
                                initialReports={fullConfig.reports || []}
                                fullSchema={schema}
                                onUpdate={(reports) => {
                                    const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
                                    if (!newConfig.reports) {
                                        newConfig.reports = []
                                    }
                                    newConfig.reports = reports;
                                    updateBuilderDocument({
                                        id: initialBuilderDocument.id,
                                        configuration: newConfig
                                    });
                                }}
                            />
                        )}
                    </Box>
                </TabPanel>
                <TabPanel
                    value={tabValue}
                    index={3}
                >
                    <Box
                        height="100%"
                        sx={{
                            overflowY: "scroll"
                        }}
                    >
                        {schema && (
                            <KeyTermsEditor
                                initialKeyTerms={fullConfig.keyTerms}
                                fullSchema={schema}
                                onUpdate={(kts) => {
                                    const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
                                    newConfig.keyTerms = kts;
                                    updateBuilderDocument({
                                        id: initialBuilderDocument.id,
                                        configuration: newConfig
                                    });
                                }}
                            />
                        )}
                    </Box>
                </TabPanel>
            </Box>
        </BuilderDocumentProvider>
    );
}

export default BuilderEditorV2;
