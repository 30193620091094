import React, { useContext, memo, useState } from "react";
import { 
    KeyTermsState, 
    HighlightsState, 
    ValidWidgetStates, 
    OtherKeyTermFilters,
    AnalysisState,
    PageElementIdentifier
} from "./states";
import { useLocalStorage } from "@uidotdev/usehooks";
import { DocumentHighlight } from "../../types/taker/documentkeyterms.generated";

export interface AdditionalAction {
    display: string;
    onClick?: () => void;
    disabled?: boolean;
}

interface WidgetStateHookData {
    getState: <S>() => S;
    mutateState: <S>(s: Partial<S>  | ((ws: S) => Partial<S>)) => void;
}

const Context = React.createContext({});

export function useWidgetState(): WidgetStateHookData {
    return useContext(Context) as WidgetStateHookData;
}

interface WidgetWrapperProps {
    widgetState: ValidWidgetStates | undefined;
    setPartialWidgetState: (partialState: Partial<ValidWidgetStates>) => void;
    children: any;
}

const WidgetWrapper = ({
    setPartialWidgetState,
    widgetState,
    children
}: WidgetWrapperProps) => {
    return (
        <Context.Provider
            value={{
                getState: () => widgetState || {},
                mutateState: (s: Partial<ValidWidgetStates>  | ((ws: ValidWidgetStates) => Partial<ValidWidgetStates>)) => {
                    if (typeof s === "function") {
                        if (widgetState === undefined) {
                            return;
                        }
                        setPartialWidgetState(s(widgetState));
                    } else {
                        setPartialWidgetState(s);
                    }
                },
            }}
        >
            {children}
        </Context.Provider>
    );
}

export default memo(WidgetWrapper);


interface KeyTermsWrapperHookData {
    state: KeyTermsState;
    mutateState: (s: Partial<KeyTermsState>  | ((ws: KeyTermsState) => Partial<KeyTermsState>)) => void;
}

const KeyTermsContext = React.createContext({});

export function useKeyTermsWrapper(): KeyTermsWrapperHookData {
    return useContext(KeyTermsContext) as KeyTermsWrapperHookData;
}

const KeyTermsWrapper = ({
    children
}: {
    children: any
}) => {
    const [groupManagerOpen, setGroupManagerOpen] = useLocalStorage<boolean>("KeyTermsState-GroupManagerOpen", false);
    const [targetFileUploadItemIds, setTargetFileUploadItemIds] = useLocalStorage<string[]>("KeyTermsState-TargetFileUploadItemIds", []);
    const [showAiSummaries, setShowAiSummaries] = useLocalStorage<boolean>("KeyTermsState-ShowAiSummaries", false);
    const [showGenerationDialog, setShowGenerationDialog] = useLocalStorage<boolean>("KeyTermsState-ShowGenerationDialog", false);
    const [panelViewMode, setPanelViewMode] = useLocalStorage<0 | 1 | 2>("KeyTermsState-PanelViewMode", 0);
    const [scrollToPage, setScrollToPage] = useLocalStorage<{ docId: string; page: number } | undefined>("KeyTermsState-ScrollToPage", undefined);
    const [scrollToElementID, setScrollToElementID] = useLocalStorage<{ docId: string; elementId: string } | undefined>("KeyTermsState-ScrollToElementID", undefined);
    const [boxHighlightMode, setBoxHighlightMode] = useLocalStorage<boolean | undefined>("KeyTermsState-BoxHighlightMode", undefined);
    const [scrollToKeyTermIdentifier, setScrollToKeyTermIdentifier] = useState<string>();
    const [keyTermIdentifierFilters, setKeyTermIdentifierFilters] = useLocalStorage<string[] | undefined>("KeyTermsState-KeyTermIdentifierFilters", []);
    const [applyFiltersToDocuments, setApplyFiltersToDocuments] = useLocalStorage<boolean>("KeyTermsState-ApplyFiltersToDocuments", false);
    const [expandedSummaries, setExpandedSummaries] = useLocalStorage<Record<string, boolean>>("KeyTermsState-ExpandedSummaries", {});
    const [keyTermOtherFilters, setKeyTermOtherFilters] = useLocalStorage<OtherKeyTermFilters[]>("KeyTermsState-KeyTermOtherFilters", []);
    const [executiveSummaryModalOpen, setExecutiveSummaryModalOpen] = useLocalStorage<boolean>("KeyTermsState-ExecutiveSummaryModalOpen", false);

    const state = {
        groupManagerOpen,
        targetFileUploadItemIds,
        showAiSummaries,
        showGenerationDialog,
        panelViewMode,
        scrollToPage,
        scrollToElementID,
        boxHighlightMode,
        scrollToKeyTermIdentifier,
        keyTermIdentifierFilters,
        applyFiltersToDocuments,
        expandedSummaries,
        keyTermOtherFilters,
        executiveSummaryModalOpen
    } as KeyTermsState;

    const setterMap = {
        groupManagerOpen: setGroupManagerOpen,
        targetFileUploadItemIds: setTargetFileUploadItemIds,
        showAiSummaries: setShowAiSummaries,
        showGenerationDialog: setShowGenerationDialog,
        panelViewMode: setPanelViewMode,
        scrollToPage: setScrollToPage,
        scrollToElementID: setScrollToElementID,
        boxHighlightMode: setBoxHighlightMode,
        scrollToKeyTermIdentifier: setScrollToKeyTermIdentifier,
        keyTermIdentifierFilters: setKeyTermIdentifierFilters,
        applyFiltersToDocuments: setApplyFiltersToDocuments,
        expandedSummaries: setExpandedSummaries,
        keyTermOtherFilters: setKeyTermOtherFilters,
        executiveSummaryModalOpen: setExecutiveSummaryModalOpen
    };

    const setPartialWidgetState = (partialState: Partial<KeyTermsState>) => {
        for (const [key, value] of Object.entries(partialState)) {            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setterMap as any)[key](value);
        }
    }

    const mutateState = (s: Partial<KeyTermsState>  | ((ws: KeyTermsState) => Partial<KeyTermsState>)) => {
        if (typeof s === "function") {
            setPartialWidgetState(s(state));
        } else {
            setPartialWidgetState(s);
        }
    };

    return (
        <KeyTermsContext.Provider
            value={{
                state,
                mutateState: mutateState,
            }}
        >
            {children}
        </KeyTermsContext.Provider>
    );
}

const MemoizedKeyTermsWrapper = memo(KeyTermsWrapper);

interface HighlightsWrapperHookData {
    highlightsState: HighlightsState;
    mutateHighlightsState: (s: Partial<HighlightsState>  | ((ws: HighlightsState) => Partial<HighlightsState>)) => void;
}

const HighlightsContext = React.createContext({});

export function useHighlightsWrapper(): HighlightsWrapperHookData {
    return useContext(HighlightsContext) as HighlightsWrapperHookData;
}

const HighlightsWrapper = ({
    children
}: {
    children: any
}) => {
    const [navigateHighlightElementIDs, setNavigateHighlightElementIDs] = useState<DocumentHighlight[] | undefined>(undefined);
    const [stickyNavigateHighlightElementIDs, setStickyNavigateHighlightElementIDs] = useState<boolean | undefined>(undefined);

    const state = {
        navigateHighlightElementIDs,
        stickyNavigateHighlightElementIDs,
    } as HighlightsState;

    const setterMap = {
        navigateHighlightElementIDs: setNavigateHighlightElementIDs,
        stickyNavigateHighlightElementIDs: setStickyNavigateHighlightElementIDs,
    };

    const setPartialWidgetState = (partialState: Partial<HighlightsState>) => {
        for (const [key, value] of Object.entries(partialState)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setterMap as any)[key](value);
        }
    }

    const mutateHighlightsState = (s: Partial<HighlightsState>  | ((ws: HighlightsState) => Partial<HighlightsState>)) => {
        if (typeof s === "function") {
            setPartialWidgetState(s(state));
        } else {
            setPartialWidgetState(s);
        }
    };

    return (
        <HighlightsContext.Provider
            value={{
                highlightsState: state,
                mutateHighlightsState: mutateHighlightsState
            }}
        >
            {children}
        </HighlightsContext.Provider>
    );
}
const MemoizedHighlightWrapper = memo(HighlightsWrapper)

interface AnalysisWrapperHookData {
    state: AnalysisState;
    mutateState: (s: Partial<AnalysisState>  | ((ws: AnalysisState) => Partial<AnalysisState>)) => void;
}

const AnalysisContext = React.createContext({});

export function useAnalysisWrapper(): AnalysisWrapperHookData {
    return useContext(AnalysisContext) as AnalysisWrapperHookData;
}

const AnalysisWrapper = ({
    children
}: {
    children: any
}) => {
    const [collapsedState, setCollapsedState] = useLocalStorage<Record<string, boolean>>('AnalysisWrapper-collapsedStateV2', {});
    const [showAnswerQuestionsDialog, setShowAnswerQuestionsDialog] = useLocalStorage<boolean>('AnalysisWrapper-showAnswerQuestionsDialog', false);
    const [selectedElement, setSelectedElement] = useLocalStorage<PageElementIdentifier | undefined>('AnalysisWrapper-selectedElement', undefined);
    const [selectedMid, setSelectedMid] = useLocalStorage<string | undefined>('AnalysisWrapper-selectedMid', undefined);
    const [targetMid, setTargetMid] = useLocalStorage<string | undefined>('AnalysisWrapper-targetMid', undefined);
    const [selectedIteration, setSelectedIteration] = useLocalStorage<number | null>('AnalysisWrapper-selectedIteration', null);
    const [controlledByQuestionnaire, setControlledByQuestionnaire] = useLocalStorage<boolean>('AnalysisWrapper-controlledByQuestionnaire', true);
    const [panelViewMode, setPanelViewMode] = useLocalStorage<0 | 1 | 2>('AnalysisWrapper-panelViewMode', 0);
    const [questionnaireElementIdFilter, setQuestionnaireElementIdFilter] = useLocalStorage<string[]>('AnalysisWrapper-questionnaireElementIdFilterV2', []);
    const [userVisibilityState, setUserVisibilityState] = useState<Record<string, boolean>>({});
    const [zoomLevel, setZoomLevel] = useState<number>();

    const state = {
        collapsedState,
        showAnswerQuestionsDialog,
        selectedElement,
        selectedMid,
        targetMid,
        selectedIteration,
        controlledByQuestionnaire,
        panelViewMode,
        questionnaireElementIdFilter,
        userVisibilityState,
        zoomLevel
    } as AnalysisState;

    const setterMap = {
        collapsedState: setCollapsedState,
        showAnswerQuestionsDialog: setShowAnswerQuestionsDialog,
        selectedElement: setSelectedElement,
        selectedMid: setSelectedMid,
        targetMid: setTargetMid,
        selectedIteration: setSelectedIteration,
        controlledByQuestionnaire: setControlledByQuestionnaire,
        panelViewMode: setPanelViewMode,
        questionnaireElementIdFilter: setQuestionnaireElementIdFilter,
        userVisibilityState: setUserVisibilityState,
        zoomLevel: setZoomLevel
    };

    const setPartialWidgetState = (partialState: Partial<AnalysisState>) => {
        for (const [key, value] of Object.entries(partialState)) {            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setterMap as any)[key](value);
        }
    }

    const mutateState = (s: Partial<AnalysisState>  | ((ws: AnalysisState) => Partial<AnalysisState>)) => {
        if (typeof s === "function") {
            setPartialWidgetState(s(state));
        } else {
            setPartialWidgetState(s);
        }
    };

    return (
        <AnalysisContext.Provider
            value={{
                state,
                mutateState: mutateState,
            }}
        >
            {children}
        </AnalysisContext.Provider>
    );
}

const MemoizedAnalysisWrapper = memo(AnalysisWrapper);

export {
    MemoizedAnalysisWrapper as AnalysisWrapper,
    MemoizedKeyTermsWrapper as KeyTermsWrapper,
    MemoizedHighlightWrapper as HighlightsWrapper
};